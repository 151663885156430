import React from 'react';

import Layout from '../../../components/layout';
import Seo from '../../../components/Seo';
import ActivityBreadCrumb from '../../../components/ActivityBreadCrumbs';
import RateActivity from '../../../components/RateActivity';

const WaterSavingGame = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Water saving game',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pb-8 pt-8 pt-8 bg-grey">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pt-5 pb-lg-0">
              <h1 className="h1-two-line-v2 purple mb-12 mb-lg-16 mt-10">
                <span>Water saving</span>
                <br />
                <span>game </span>
              </h1>
              <div
                style={{
                  position: 'absolute',
                  top: '190px',
                  left: '530px',
                  height: '120px',
                  width: '120px',
                  transform: 'rotate(180deg)',
                  zIndex: '10',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Arrows/Arrow-3.svg"
                  alt="Illustration of an arrow"
                />
              </div>
              <div className="ml-0 ml-xl-15">
                <h3>
                  We hope you have learnt lots of ways to save water from the
                  spot the difference activity. If you missed one, you may find
                  it while playing this game.
                </h3>
                <p>
                  Make sure you have all of the activities you need before you
                  begin, and don’t forget to have fun!
                </p>
                <div style={{ position: 'relative' }}>
                  <a
                    href="https://cdn.southeastwater.co.uk/aquasmart/mission-1/SEW-Water-Saving-Game_V5.pdf"
                    target="_blank"
                    className="btn btn-purple mt-3"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      position: 'absolute',
                      top: '-80px',
                      left: '200px',
                      height: '135px',
                      width: '135px',
                      transform: 'rotate(180deg)',
                    }}
                    className="d-none d-lg-block"
                  >
                    <img
                      src="/images/Doodles/Arrows/Arrow-1.svg"
                      alt="Illustration of an arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src="/images/Activities/MissionOne/Aqua-Board-Game.svg"
                className="w-100"
                alt="Aqua board game"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <RateActivity activity="Water Saving Game" />
  </Layout>
);

export default WaterSavingGame;
